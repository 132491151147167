import { useConfig } from '~/composables';
export const getHelpLink = (): string => {
  const { config } = useConfig();
  const storeCode = config.value?.store_code;

  const linksMap: { [key: string]: string } = {
    fr_fr: 'https://serviceclient.diptyqueparis.com/hc/fr-fr',
    fr_eu: 'https://serviceclient.diptyqueparis.com/hc/fr-fr',
    en_eu: 'https://customerserviceeurope.diptyqueparis.com/hc/en-be',
    en_uk: 'https://customerservice.diptyqueparis.com/hc/en-gb',
    de_eu: 'https://kundenservice.diptyqueparis.com/hc/de',
    it_eu: 'https://servizioclienti.diptyqueparis.com/hc/it',
    es_eu: 'https://servicioalcliente.diptyqueparis.com/hc/es-es',
    ja_jp: 'https://diptyquejp.zendesk.com/hc/ja',
    en_hk: 'https://www.diptyqueparis.com/en_hk/c/faq.html',
    zh_hk: 'https://www.diptyqueparis.com/zh_hk/c/faq.html'
  };

  return linksMap[storeCode] || '#';
};
